<template>
  <main role="main" class="contract">
    <div class="contract__view">
      <router-view />
    </div>
  </main>
</template>

<style lang="scss">
@import "@/scss/lib";

.contract {
  padding: 0;
  color: $color-text;

  .button.inactive {
    opacity: 0.5;
  }

  p,
  input,
  select,
  textarea,
  form,
  .input-wrapper,
  .input-textarea {
    max-width: 600px;
    width: 100%;
  }

  p {
    @include label;
  }

  .input-error-message {
    border: 1px solid #ea4040;
    border-radius: $border-radius;
    max-width: 100%;
    padding: 8px 0;
  }

  .intro {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;

    .btn {
      border: 1px solid $color-white;

      svg {
        fill: $color-white;
        margin: 3px 6px -2px;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    .input-wrapper {
      margin: 0 0 20px;
    }

    label {
      @include label;
      margin: 0 0 4px;
    }

    select {
      height: 48px;
    }
  }

  .trainers {
    display: flex;
    width: 100%;
    margin: 16px 0 32px;

    h3 {
      font-size: 36px;
      line-height: 36px;
      margin: 0;
    }

    h4 {
      font-size: 20px;
      margin: 0 0 8px;
    }

    p {
      margin-top: 20px;
    }

    .trainer_img {
      float: left;
      position: relative;
      background: #eeeeee;
      height: fit-content;
      max-height: 400px;
      width: 60%;
      padding: 0;
      overflow: hidden;
      border-radius: $border-radius;

      img {
        width: 100%;
        float: right;
        margin: 0;
      }
    }

    .trainer_text {
      float: left;
      width: 40%;
      text-align: left;
      padding: 0 20px;
      margin: 5px 0 0;
    }
  }

  .summary {
    padding: 32px;
    border-radius: $border-radius;
    text-align: center;
    background: $color-beige;

    p {
      margin: 0 auto;
    }
  }

  .contact {
    align-self: flex-end;
    text-align: right;

    .btn {
      border-radius: 5px;
      margin: 5px 0 0;
    }
  }

  #contract_footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 0 0;

    >div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 50%;

      &:last-child {
        align-self: flex-end;
        padding: 0 10px 0 0;

        a {
          align-self: flex-start;
        }
      }

      &:first-child {
        padding: 0 0 0 10px;
      }

      p {
        width: 100%;
        max-width: 368px;
        margin: 0 0 15px;
        text-align: center;
      }
    }
  }

  @include mobile {
    .intro {
      .container {
        flex-direction: column;

        .contact_component {
          align-self: flex-start;
          text-align: left;
          margin: 30px 0 0;
          padding: 0;
          max-width: 600px;
          width: 100%;
        }
      }
    }

    .trainers,
    .trainers:nth-child(odd) {
      flex-direction: column;

      .trainer_img,
      .trainer_text {
        width: 100%;
      }

      .trainer_img {
        margin: 0 0 10px;
      }
    }

    #contract_footer {
      flex-direction: column-reverse;

      div {
        max-width: 100%;

        &:first-child,
        &:last-child {
          align-self: normal;
          padding: 0 0 10px;
        }

        &:first-child {
          margin: 0;
        }

        p {
          margin: 45px 0 15px;
        }
      }
    }
  }
}

.contract__view {
  margin: 0 0 64px 0;

  &:last-child {
    margin: 0;
  }
}
</style>